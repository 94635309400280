<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Pessoas Físicas</h1>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div class="p-2 flex-grow-1"></div>
          <div class="p-2">
            <router-link
              v-if="$can('physical_people:create')"
              :to="{
                name: 'physical_people_create'
              }"
              class="btn btn-outline-primary ml-auto link-edit"
            >
              Novo
              <b-icon
                icon="plus-circle"
                aria-hidden="true"
                class="mx-auto my-auto pl-1"
              ></b-icon
            ></router-link>
          </div>
        </div>

        <b-table striped hover :items="physical_people" :fields="fields">
          <template #cell(cpf)="row">
            {{ row.item.cpf | cpf }}
          </template>
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="false"
              :view_edit="$can('physical_people:edit')"
              :view_delete="$can('physical_people:delete')"
              edit_to="physical_people_edit"
              @delete="delete_physical_person"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapState("physical_people", ["physical_people", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "cpf", label: "CPF" },
        { key: "rg", label: "RG" },
        { key: "address.county.name", label: "Município" },
        { key: "actions", label: "#" }
      ];
    }
  },
  methods: {
    ...mapActions("physical_people", ["ActionGetAll", "ActionDelete"]),

    async delete_physical_person(person) {
      try {
        await this.ActionDelete(person.id);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success"
        });
        await this.ActionGetAll({});
      } catch (error) {
        console.log("error", error.data);
        this.$notifications.addNotification({
          message: "Erro ao excluir registro.<br/>" + error.data.message,
          type: "danger"
        });
      }
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    }
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style></style>
